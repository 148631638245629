import React from 'react';
import ESP_0379 from './ESP_0379 1.jpg';
//import ESP_3530 from './ESP_3530 1.jpg';
import ESP_4522 from './ESP_4522 1.jpg';
import ESP_7059 from './ESP_7059 1.jpg';
//import ESP_7900 from './ESP_7900 1.jpg';
import ESP_9141 from './ESP_9141 1.jpg';
//import ESP_3232 from './ESP_3232.jpg';

import '../../photogrid.css';

function PhotoGrid() {
    return (
        <div>
            <div className="row">
                <div className="column">
                    
                    <img src={ESP_0379} alt="ESP 0379" />

                </div>
                <div className="column">
                    <img src={ESP_4522} alt="ESP 4522" />
                    <img src={ESP_9141} alt="ESP 9141" />
                </div>
                <div className="column">
                
                    {/* <img src={ESP_7900} alt="ESP 7900" /> */}
                    <img src={ESP_7059} alt="ESP 7059" />
                    {/* <img src={ESP_3530} alt="ESP 3530" /> */}

                </div>
            </div>
        </div>
    );
}

export default PhotoGrid;
