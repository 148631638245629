import React from 'react'

import pic1 from '../media/pics/dodatneponude/dodatneponude.png';
import pic2 from '../media/pics/dodatneponude/dodatneponude-1.png';
import pic3 from '../media/pics/dodatneponude/dodatneponude-2.png';
import pic4 from '../media/pics/dodatneponude/dodatneponude-3.png';

import dodatnoIzrada from '../media/pics/dodatneponude/dodatnoIzrada.png';

function DodatnePonude() {
  return (
    <div className='bg-[#392A0C]  p-4 pt-6 text-white' id="dodatneponude">
      <h3 className='text-3xl text-center pt-6 pb-6 text-white font-bold'>Dodatne ponude</h3>
      <p className='text-balance text-center p-4'>Uz našu uslugu fotografiranja vjenčanja, želimo da osiguramo da vaš dan bude pun emocija i ljubavi.<br/><br/>
      Stoga pružamo i dodatne usluge kao što su grafička izrada pozivnica, detalja sa porukama, spisak zvanica i rasporeda stolova.<br/><br/>
      Sve to kreiramo s ljubavlju i pažnjom, kako bi svaki detalj bio na svom mjestu i kako bi vaš dan ostao nezaboravan.</p>


      <div className='images flex flex-wrap gap-3 justify-center mt-[50px] mb-[70px]'>

        <img src={pic1}  alt="pic1" />
        <img src={pic2}  alt="pic2" />
        <img src={pic3}  alt="pic3" />
        <img src={pic4}  alt="pic4" />


      </div>

      <p className='mt-6 text-balance text-center p-4'>Izrada jedinstvenih kutija, dekoracija i poklona za Vaše vjenčanje</p>

      <img src={dodatnoIzrada} alt="dodatnoIzrada" className='mx-auto mt-6 mb-6'/>

    </div>
  )
}

export default DodatnePonude