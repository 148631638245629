import React from 'react';
import PhotoGrid from '../media/pics';


const GalleryComponent = () => {
 

  return (
    <div className="mx-auto p-4">
     <PhotoGrid/> 
    </div>
  );
};

export default GalleryComponent;
