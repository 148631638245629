import posebneNamjene from '../media/pics/packages/posebneNamjene.jpg';

function PosebneNamjeneComponent() {
  const packages = [
    {
      "name": "Custom",
      "duration": "1 sat",
      "delivery":"15 dana",
      "details": [
        "1 Fotograf",
        "Oko 50 obrađenih fotografija",
        "Online galerija"
      ],
      "price": "60 €",
        "bgColor": "#B47636",
        "imgPosition": "left",
        image: posebneNamjene
    }
  ];

  return (
    <div className="p-4 container-fluid bg-white mx-auto">
      <div className='textHolder text-center m-5'>
        <h1 className="font-bold text-4xl mb-3">Posebne namjene</h1>
        <h3 className='text-3xl'>Rođendan / Trudnice / Event</h3>
      </div>
      <div className="flex flex-col container mx-auto ">
        {packages.map((packageItem, index) => (
          <div key={index} className="flex flex-col sm:flex-row py-4 hover:translate-y-[-10px] transition ease-in duration-600">
            <div className={`flex-1 ${packageItem.imgPosition === 'left' ? 'sm:order-1' : 'sm:order-2'}`}>
              <img src={packageItem.image} alt={packageItem.name} className="w-full h-auto" />
            </div>
            <div className={`flex-1 p-5 bg-[${packageItem.bgColor}] lg:pl-20 text-white flex flex-col justify-center text-left ${packageItem.imgPosition === 'left' ? 'sm:order-2' : 'sm:order-1'}`}>
              <h2 className="lg:text-4xl text-3xl mb-2 uppercase">{packageItem.name} • {packageItem.price}</h2>
              <p className="mb-2">Trajanje: {packageItem.duration}</p>

              {packageItem.details.map((detail, index) => (
                <li key={index} className="mb-2 text-xs">{detail}</li>
              ))}

              <p className='mt-2 text-xs'>Vrijeme za dostavu <span className='font-bold'>{packageItem.delivery}</span></p>

            </div>
          </div>
        ))}
      </div>
    </div>
  );
}


export default PosebneNamjeneComponent;