import React from 'react';
import logo from '../media/Logo.png';

import instagramIcon from '../media/instagramIcon.svg';
import facebookIcon from '../media/facebookIcon.svg';

function Footer() {
  return (
    <footer className="bg-[#333333] text-white py-4 p-6" id='footer'>
      <div className="container mx-auto p-4">
        <div className="text-center mb-4 border-b border-gray-200">
            <img src={logo} alt="logo"/>
        </div>
        
        <div className='container text-white mx-auto flex gap-10 lg:flex flex-wrap'> 


          {/* Lijevo */}
        <div className="lijevo">
        <div className="text-sm mt-4">
          <p>Tel/Viber/WhatsApp</p>
          <p className='font-bold'>+387 61 680 413</p>
        </div>

        <div className="text-sm mt-4">
          <p>Email</p>
          <p className='font-bold'>selimovicerminphoto@gmail.com</p>
        </div>
        </div>

        

        
        {/* Desno */}
        <div className="desno">
        <div className="text-sm mt-4">
          <p>Dostupan</p>
          <p className='font-bold'>Tuzla / TK / BiH / Europa</p>
        </div>

        <div className="text-sm mt-4">
          <p><span><image src={instagramIcon}/></span> infinitycreative.agency</p>
          <p><span><image src={facebookIcon}/></span> Ermin Selimović Photography</p>
        </div>
        </div>


        </div>
      </div>
    </footer>
  );
}

export default Footer;