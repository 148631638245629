import React from 'react';

function CestoPostavljenaPitanja() {
 
  const QandA = [
    {
      id: 1,
      question: "Da li radite izvan BiH?",
      answer: "Naravno! Sa radošću putujem i radim izvan granica BiH, a uz to govorim engleski jezik kako bih olakšao komunikaciju. Putni troškovi i smještaj su na klijentu, ali bih vam rado pomogao pronaći pristupačne i udobne opcije smještaja.",
    },
    {
      id: 2,
      question: "Da li objavljujete fotografije i wedding film?",
      answer: "Obično volim dijeliti najljepše trenutke na društvenim mrežama, ali vaša privatnost mi je prioritet. Ako želite da vaše fotografije i videi ostanu privatni, dovoljno je samo da to navedete kao zahtjev.",
    },
    {
      id: 3,
      question: "Da li ste ikada bili bolesni na dan vjenčanja?",
      answer: "Moje zdravlje je važno, ali vaša sreća na vjenčanju mi je na prvom mjestu. Uvijek ću dati sve od sebe da budem prisutan i zabilježim vaš poseban dan, čak i ako se ne osjećam najbolje. U slučaju da zaista ne mogu doći, uvijek imam plan B i osigurat ću zamjenu koja će se potruditi jednako kao i ja.",
    },
    {
      id: 4,
      question: "Na koji način radite?",
      answer: "Volim prirodne i spontane fotografije koje hvataju pravi trenutak, ali ako želite, možemo se posvetiti i pažljivo planiranim pozama. Na kraju, najvažnije je da se vi osjećate opušteno i sretno.",
    },
    {
      id: 5,
      question: "Kada možemo očekivati fotografije?",
      answer: "Obično će vam fotografije stići u roku od 30 dana, ali da vas ne bih držao u neizvjesnosti, uvijek šaljem mali pregled nekoliko dana nakon vjenčanja.",
    },
    {
      id: 6,
      question: "Kako ste odjeveni na dan vjenčanja?",
      answer: "Vaš dan je poseban, i zato se trudim da se obučem elegantno, obično u odijelo, kako bih se uklopio u atmosferu i ne skretao pažnju s vas dvoje.",
    },
    {
      id: 7,
      question: "Da li te možemo upoznati?",
      answer: "Naravno! Bit će mi zadovoljstvo da vas upoznam prije velikog dana, bilo uživo ili putem telefona ili video poziva. Fleksibilan sam i otvoren za sve opcije koje vama najviše odgovaraju.",
    }
  ];

  return (
    <div className='bg-white p-3 lg:p-12' id='QandA'>
      <h3 className='text-3xl text-center p-12 font-bold'>Često postavljena pitanja</h3>
      <div className='w-full max-w-4xl mx-auto'>

        {QandA.map((item) => (
          <div key={item.id} className='border border-gray-300 rounded-lg m-4 p-4 hover:bg-gray-500 hover:text-white transition duration-300'>

            <h3 className='font-bold'><span className="flex items-center text-lg mb-2">
              {item.question}
            </span>
            </h3>
            <p>{item.answer}</p>
          </div>

        ))
        }
      </div>
    </div>
  );
}

export default CestoPostavljenaPitanja;
