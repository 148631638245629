import React from 'react'
import MenuComponent from './MenuComponent'
import HeroComponent from './HeroComponent'

function HeaderComponent() {
  return (
    <>
    <MenuComponent/>
    <HeroComponent/>
    </>
  )
}

export default HeaderComponent