import React from 'react'

function WelcomeComponent() {
  return (
    <div className='text-white container mx-auto p-6 text-center m-6'>

        <h3 className='text-3xl p-3'>Dobrodošli</h3>
        <p className='lg:text-xl text-xs text-balance'>Moja strast je hvatanje najljepših i najemotivnijih trenutaka vašeg života kroz objektiv. <br/>Sa bogatim iskustvom i umetničkim pristupom, posvećen sam stvaranju fotografija koje ne samo da dokumentuju događaje, već i pričaju priču.</p>


    </div>
  )
}

export default WelcomeComponent