import React, { useEffect, useState } from 'react';
import Logo from '../media/Logo.png';
import sviPaketi from '../data/sviPaketi.js';
import dodatnePonudeData from '../data/dodatneponude.js';
import ImportatInfo from './ImportatInfo.js';
import CestoPostavljenaPitanja from './CestoPostavljenaPitanja.js';
import DodatnePonude from './DodatnePonude.js';
import Footer from './Footer.js';


function Package({ id }) {
    const [selectedPackage, setSelectedPackage] = useState(null);
    const [selectedOffer, setSelectedOffer] = useState(null);
    const [remainingTime, setRemainingTime] = useState('');
    const [prikaziPopust, setPrikaziPopust] = useState(true);
    const [isLoading, setIsLoading] = useState(true);  // Dodajemo stanje za loader

    const svePonudeApi = "https://script.google.com/macros/s/AKfycbxVdjOH4FxDPUByrjHL7QP1EOAAuvo4UhBdlXOL75FzHk1vPvUp77XIk4F1J-o-kspN/exec";

    useEffect(() => {
        async function fetchData() {
            try {
                const response = await fetch(svePonudeApi); // Fetch podataka sa API-ja
                const ponudeData = await response.json();

                const ponudaPaketa = ponudeData.find((ponuda) => ponuda.id === id);
                setSelectedOffer(ponudaPaketa);

                if (ponudaPaketa) {
                    const paket = sviPaketi().find((paket) => paket.id === ponudaPaketa.paket) || 
                                  dodatnePonudeData().find((dodatnaPonuda) => dodatnaPonuda.id === ponudaPaketa.paket);
                    
                    setSelectedPackage(paket);
                }
            } catch (error) {
                console.error("Greška prilikom dohvaćanja podataka:", error);
            } finally {
                setIsLoading(false);  // Nakon što se podaci učitaju, isključujemo loader
            }
        }

        fetchData();
    }, [id]);

    useEffect(() => {
        if (selectedOffer && selectedOffer.datumPopusta) {
            const countdown = setInterval(() => {
                const now = new Date();
                const offerEndDate = new Date(selectedOffer.datumPopusta);
                const timeDifference = offerEndDate - now;

                if (timeDifference > 0) {
                    const days = Math.floor(timeDifference / (1000 * 60 * 60 * 24));
                    const hours = Math.floor((timeDifference % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
                    const minutes = Math.floor((timeDifference % (1000 * 60 * 60)) / (1000 * 60));
                    const seconds = Math.floor((timeDifference % (1000 * 60)) / 1000);

                    setRemainingTime(`${days} dan/a ${hours}h ${minutes}m ${seconds}s`);
                } else {
                    clearInterval(countdown);
                    setRemainingTime('Ponuda je istekla');
                    setPrikaziPopust(false);
                }
            }, 1000);

            return () => clearInterval(countdown);
        }
    }, [selectedOffer]);

    // Funkcija za formatiranje datuma u obliku dd:mm:yyyy
    const formatDate = (dateString) => {
        const date = new Date(dateString);
        const day = date.getDate().toString().padStart(2, '0');
        const month = (date.getMonth() + 1).toString().padStart(2, '0'); // Dodajemo 1 jer su mjeseci 0-indeksirani
        const year = date.getFullYear();
        return `${day}.${month}.${year}. god.`;
    };

    if (isLoading) {  // Prikazujemo loader dok se podaci učitavaju
        return (
            <div className="loader-container flex items-center justify-center h-screen">
                <div className="loader w-16 h-16 border-2 border-t-4 border-gray-200 bg-gradient-to-r from-cyan-500 to-blue-500 rounded-full animate-spin"></div>
            </div>
        );
    }

    if (!selectedPackage || !selectedOffer) {
        return <p>Podaci nisu dostupni.</p>;
    }

    return (
        <>
            <div className='bg-[#1D1C1A] text-white h-[90vh] lg:h-[90vh] hero flex flex-col justify-center items-center'>
                <div className='text-center m-auto h-[20vh] lg:h-[20vh] p-3'>
                    <div className='hero-logo'>
                        <img src={Logo} alt="logo" className='mx-auto' />
                        <p className='text-balance'>Ponuda</p>
                    </div>
                </div>

                <div className='m-4 text-center' id='packages'>
                    <p className='text-6xl lg:text-8xl pisaniFont'>{selectedOffer.name}</p>
                    <p className='text-xl lg:text-2xl pt-2'>{formatDate(selectedOffer.datum)}</p> {/* Formatirani datum */}
                    <div className='animate-bounce'><p className='text-5xl pisaniFont premaDole'>></p></div>
                </div>
            </div>

            {/* Paket */}
            <div className='bg-white'>
                <div className='p-6 flex justify-center flex-wrap'>
                    <h3 className='text-xl uppercase mt-9'>Vaš odabrani paket</h3>
                </div>
                <p className='text-4xl lg:text-6xl p-2 uppercase text-center'>{selectedOffer.nazivPaketa}<br/><strong className='text-5xl bolder'>{selectedOffer.cijena}</strong></p>
                <p className='text-xl uppercase text-center'>{selectedOffer.trajanjePaketa}</p>

                <div className='p-6 flex justify-center flex-col text-center'>
                    <h3 className='text-xl uppercase mt-9 pb-2'><span className='border-b-2 border-yellow-500'>Šta je uključeno</span></h3>
                    {selectedPackage.details.map((detail, index) => (
                        <p className='text-center font-bold' key={index}>{detail}</p>
                    ))}
                    <br />
                    <p>Vrijeme za dostavu je {selectedPackage.delivery}</p>
                </div>

                {prikaziPopust && selectedOffer.popust ? (
                    <div className='p-6 flex justify-center flex-col text-center'>
                        <h3 className='text-xl uppercase mt-9'>Specijalna ponuda</h3>
                        <p className='text-center text-6xl font-bold mt-4'>
                            <span className='bg-yellow-500 py-2 px-7 rounded'>-{selectedOffer.popust}</span>
                        </p>

                        <h3 className='text-xl uppercase mt-9'>Ova ponuda vrijedi još</h3>
                        <p className='text-center text-2xl font-bold'>{remainingTime}</p>
                        <p>Ukoliko rezervišete termin u navedenom roku, dobit ćete popust od <span className='font-bold'>{selectedOffer.popust}</span></p>
                    </div>
                ) : !prikaziPopust && (
                    <div className='p-6 flex justify-center flex-col text-center'>
                        <h3 className='text-xl uppercase mt-9 text-red-500'>Vaš popust je istekao</h3>
                    </div>
                )}

                {selectedOffer.paket <= 4 && (
                    <div className='p-6 text-center'>
                        <h3 className='text-xl uppercase pb-2'><span className='border-b-2 border-yellow-500'>Kako rezervisati</span></h3>
                        <p className='font-bold'>Veoma je lako</p>
                        <p className='text-balance'>Da bi se rezervisao bilo koji od paketa potrebno je uplatiti <span className='font-bold'>150€ avansa.</span><br /> Ova suma se oduzima od ukupne cijene
                            te vam garanduje da je taj datum samo vaš.</p>
                    </div>
                )}

                {selectedOffer.dodatneInformacije && (
                    <div className='p-6 text-center'>
                        <ImportatInfo />
                       
                    </div>
                )}
                <DodatnePonude/>
                <CestoPostavljenaPitanja />
                <Footer/>
            </div>
        </>
    );
}

export default Package;
