import React, { useState, useEffect } from 'react';

import Logo from '../media/Logo.png'

const MenuComponent = ({showAll=true}) => {
  const [isOpen, setIsOpen] = useState(false);

  const toggleMenu = () => {
    setIsOpen(!isOpen);
  };

  const closeMenu = () => {
    setIsOpen(false);
  };

  // Close the menu when clicking outside of it
  useEffect(() => {
    const handleClickOutside = (event) => {
      if (isOpen && !event.target.closest('nav') && !event.target.closest('button')) {
        closeMenu();
      }
    };

    document.addEventListener('click', handleClickOutside);
    return () => {
      document.removeEventListener('click', handleClickOutside);
    };
  }, [isOpen]);

  return (
    <header className="bg-[#1D1C1A] text-white p-2 m-auto sticky top-0 z-50" id='home'>
      <div className="flex items-center justify-between p-3 container mx-auto">
        {/* Logo */}
        <div className="text-xl font-bold">
          <img src={Logo} alt="Logo" className="h-12 scale-125"/>
        </div>

        {/* Menu Button for Mobile */}
        <div className="md:hidden z-50">
          <button onClick={toggleMenu} className="focus:outline-none">
            <svg
              className="w-6 h-6"
              fill="none"
              stroke="currentColor"
              viewBox="0 0 24 24"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
               
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="2"
                d={isOpen ? 'M6 18L18 6M6 6l12 12' : 'M4 6h16M4 12h16m-7 6h7'}
              />
            </svg>
          </button>
        </div>

        {/* Menu Items */}
        <nav
          className={`fixed inset-y-0 right-0 transform z-[20] ${
            isOpen ? 'translate-x-0' : 'translate-x-full'
          } transition-transform duration-300 ease-in-out bg-[#1D1C1A] w-2/3 md:w-auto md:static md:flex md:transform-none md:items-center z-[100]`}
        >
          <ul className="flex flex-col md:flex-row md:space-x-8 p-8 md:p-0">
            <li>
              <a href="/" className="block py-2 text-lg md:py-0 hover:border-b border-white" onClick={closeMenu}>
                Početna
              </a>
            </li>
            {showAll && (
              <>
              <li>
              <a href="#packages" className="block py-2 text-lg md:py-0 hover:border-b border-white" onClick={closeMenu}>
                Paketi
              </a>
            </li>
            <li>
              <a href="#dodatneponude" className="block py-2 text-lg md:py-0 hover:border-b border-white" onClick={closeMenu}>
                Dodatne ponude
              </a>
            </li>
            <li>
              <a href="#QandA" className="block py-2 text-lg md:py-0 hover:border-b border-white" onClick={closeMenu}>
                Često postavljena pitanja
              </a>
            </li>
            <li>
              <a href="#footer" className="block py-2 text-lg md:py-0 hover:border-b border-white" onClick={closeMenu}>
                Kontakti
              </a>
            </li>
              </>
            )}
            
          </ul>
        </nav>
      </div>
    </header>
  );
};

export default MenuComponent;
