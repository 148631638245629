import React from 'react'

function ImportatInfo() {

  const ImportatInfos = [
    {
      id: 1,
      naslov: 'Troškovi',
      opis: 'Cijena ne uključuje sve dodatne troškove. Ukoliko se lokacije nalaze izvan Tuzlanskog kantona, potrebno je pokriti troškove prevoza kao i osigurati smještaj za fotografa i snimatelja.'
    },
    {
      id: 2,
      naslov: 'Isporuka fotografija',
      opis: 'Fotografije će biti dostavljene u roku od 60 dana ukoliko je vjenčanje u sezoni. 2-3 dana nakon vjenčanja mladenci će dobiti link sa nekoliko obrađenih fotografija. RAW (original) materijal ne dostavljamo.'
    },
    {
      id: 3,
      naslov: 'Isporuka video materijala',
      opis: 'Isporuka video materijala traje 2-3 mjeseca. Reklamacije se uvažavaju u roku od 5 dana nakon pregleda materijala. Snimanje na USB je uključeno'
    },
    {
      id: 4,
      naslov: 'Photobook',
      opis: 'Wedding Photobook: Cijena Wedding Photobook-a varira između 200-250 KM, zavisno od modela koji mladenci odaberu. Saradnja sa SKYBOOK.'
    },
    {
      id: 5,
      naslov: 'Dodatno fotografisanje',
      opis: 'Ako želite da fotograf ostane duže od dogovorene satnice paketa, svaki dodatni sat će biti naplaćen 100 KM.Plaćanje se vrši na dan vjenčanja nakon završetka fotografisanja.'
    },
    {
      id: 6,
      naslov: 'Dodatni USB',
      opis: 'Cijena dodatnog USB-a iznosi 25 KM.'
    },
    ]



  return (
    <>
    
    <p className='text-xl uppercase mt-9 pb-2'><span className='border-b-2 border-yellow-500'>Bitno da znate</span></p>

    <div className='container mx-auto grid grid-cols-1 md:grid-cols-2'>
    {ImportatInfos.map((info) => (
      <div key={info.id} className='p-3 text-left'>
        <p className='text-lg font-bold'>{info.naslov}</p>
        <p className='text-lg text-balance'>{info.opis}</p>
      </div>
    ))}
    </div>


    
    </>
  )
}

export default ImportatInfo