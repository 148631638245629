import miniImg from '../media/pics/packages/mini.png';
import lightImg from '../media/pics/packages/light.png';
import classicImg from '../media/pics/packages/classic.png';
import videoImg from '../media/pics/packages/full.png';
function sviPaketi() {

  const paketi = [
    {
      "id": 1,
      "name": "Amalfi",
      "duration": "6 sati",
      "delivery": "60 dana",
      "details": [
        "1 Fotograf",
        "Oko 500 obrađenih fotografija",
        "Unikatni Wedding box kutijca sa stickom",
        "Online galerija"
      ],
      "price": "400 €",
      "bgColor": "#797248",
      "imgPosition": "left",
      image: miniImg
    },
    {
      "id": 2,
      "name": "Tuscany",
      "duration": "8 sati",
      "delivery": "60 dana",
      "details": [
        "1 Fotograf",
        "Oko 600 obrađenih fotografija",
        "Unikatni Wedding box kutijca sa stickom",
        "Online galerija"
      ],
      "price": "600 €",
      "bgColor": "#936D58",
      "imgPosition": "right",
      image: lightImg
    },
    {
      "id": 3,
      "name": "Valencia",
      "duration": "12 sati",
      "delivery": "60 dana",
      "details": [
        "1 Fotograf",
        "Oko 700 obrađenih fotografija",
        "Unikatni Wedding box kutijca sa stickom",
        "Online galerija"
      ],
      "price": "800 €",
      "bgColor": "#6E6E6E",
      "imgPosition": "left",
      image: classicImg
    },
    {
      "id": 4,
      "name": "Andalusia",
      "duration": "12 sati",
      "delivery": "60 dana",
      "details": [
        "Fotografisanje i snimanje",
        "Jedna lokacija po želji",
        "Wedding Box sa 50 izrađenih fotografija",
        "1 USB sa fotografijama i videom",
        "Dodatne usluge po dogovoru"
      ],
      "price": "Cijena na upit.",
      "bgColor": "#98844C",
      "imgPosition": "right",
      image: videoImg
    }
  ];

  return paketi;
}

export default sviPaketi
