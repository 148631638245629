import React from 'react';
import paketi from '../data/sviPaketi';

function PaketiComponent() {
  const packages = paketi(); // Koristite ponude() za dobijanje paketa

  return (
    <div className="p-4 container-fluid bg-white mx-auto" id="packages">
      <div className='textHolder text-center m-5'>
        <h1 className="text-3xl">Paketi</h1>
        <h3 className='font-bold text-4xl'>Vjenčanja</h3>
      </div>
      <div className="flex flex-col container mx-auto ">
        {packages.map((packageItem, index) => (
          <div key={index} className="flex flex-col sm:flex-row py-4 opacity-80 hover:translate-y-[-5px] hover:opacity-100 transition ease-in duration-600">
            <div className={`flex-1 ${packageItem.imgPosition === 'left' ? 'sm:order-1' : 'sm:order-2'}`}>
              <img src={packageItem.image} alt={packageItem.name} className="w-full h-auto" />
            </div>
            <div className={`flex-1 p-5 bg-[${packageItem.bgColor}] lg:pl-20 text-white flex flex-col justify-center text-left ${packageItem.imgPosition === 'left' ? 'sm:order-2' : 'sm:order-1'}`}>
              <h2 className="lg:text-4xl text-3xl mb-2 uppercase">{packageItem.name} • {packageItem.price}</h2>
              <p className="mb-2">Trajanje: {packageItem.duration}</p>

              {packageItem.details.map((detail, index) => (
                <li key={index} className="mb-2 text-xs">{detail}</li>
              ))}

              <p className='mt-2 text-xs'>Vrijeme za dostavu <span className='font-bold'>{packageItem.delivery}</span></p>

            </div>
          </div>
        ))}

       
      </div>
    </div>
  );
}

export default PaketiComponent;
