import instagramIcon from '../media/instagramIcon.svg';


function InstagramFollow() {
  return (
<>

<h3 className='text-center mt-7 text-white'>Zapratite nas na Instagramu</h3>
<a href="https://www.instagram.com/infinitycreative.agency/" target="_blank" className='flex flex-col items-center mx-auto text-white' rel="noopener noreferrer"><img src={instagramIcon}  alt="instagramIcon" className='mx-auto mt-6 mb-6'/>weddings_infinity</a>



</>


  )
}

export default InstagramFollow