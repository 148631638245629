import React from 'react'
import HeaderComponent from './HeaderComponent';
import NasePriceComponent from './NasePriceComponent';
import PaketiComponent from './PaketiComponent';
import PosebneNamjeneComponent from './PosebneNamjeneComponent';
import DodatnePonude from './DodatnePonude';
import CestoPostavljenaPitanja from './CestoPostavljenaPitanja';
import Footer from './Footer';
import WelcomeComponent from './WelcomeComponent';

function HomeComponents() {
  return (
    <div>
    <HeaderComponent/>
    <WelcomeComponent/>
    <NasePriceComponent/>
    <PaketiComponent/>
    <PosebneNamjeneComponent/>
    <DodatnePonude/>
    <CestoPostavljenaPitanja/>
    <Footer/>
    </div>
  )
}

export default HomeComponents;