import posebneNamjene from '../media/pics/packages/posebneNamjene.jpg';
function dodatnePonude() {

  const additionalpackages = [
    {
      "id": 5,
      "name": "Custom",
      "duration": "1 sat",
      "delivery": "15 dana",
      "details": [
        "1 Fotograf",
        "Oko 50 obrađenih fotografija",
        "Online galerija"
      ],
      "price": "60 €",
      "bgColor": "#B47636",
      "imgPosition": "left",
      image: posebneNamjene
    }
  ];

  return additionalpackages;
}

export default dodatnePonude;