
import { useParams } from 'react-router-dom';
import MenuComponent from '../components/MenuComponent';

import Package from '../components/Package';

const PackagePage = () => {
    const { id } = useParams(); // Dohvatanje parametra iz URL-a
    
    
    
    return (
        <>
    
        <MenuComponent showAll={false}/>
        <Package id={id}/>
        

        </>
    );
};

export default PackagePage;
