import React from 'react'

import Logo from '../media/Logo.png'

function HeroComponent() {
  return (
    
    <div className='bg-[#1D1C1A] text-white h-[90vh] hero flex justify-center items-center'>

      <div className='hero-logoHeader text-center m-auto h-[30vh] lg:h-[30vh] p-3'>

        <div className='hero-logo'>
          <img src={Logo} alt="logo" className='mx-auto'/>
          <p className='text-balance'>Dokumentujemo vaše uspomene s ljubavlju i pažnjom.</p>
        </div>

      </div>


    </div>
    
  )
}

export default HeroComponent