import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import Footer from "../components/Footer";
import MenuComponent from "../components/MenuComponent";
import clients from "../data/clients"; // Assuming this is a function or module that fetches client data.
import Logo from "../media/Logo.png";
import Loading from "../media/loading.svg";
import InstagramFollow from "../components/InstagramFollow";

function ClientPage() {
    const { name } = useParams(); // Extracting the client name from the URL parameters
    const [clientData, setClientData] = useState(null); // State to store the client data
    const [loading, setLoading] = useState(true); // State to manage loading state
    const [password, setPassword] = useState(""); // State to store user-entered password
    const [isAuthorized, setIsAuthorized] = useState(false); // State to track if the user is authorized

    useEffect(() => {
        async function fetchData() {
            try {
                setLoading(true); // Start loading
                const allClients = await clients(); // Fetch all clients data (assuming clients() is async)
                const foundClient = allClients.find((client) => client.name === name); // Find client by name
                if (foundClient) {
                    setClientData(foundClient); // Set the client data in state
                    // If redirect is true, automatically authorize and start redirect process
                    if (foundClient.redirect === "true") {
                        setIsAuthorized(true);
                        setTimeout(() => {
                            window.location.href = foundClient.url || foundClient.gostiUrl;
                        }, 3000);
                    }
                } else {
                    setClientData(null); // Handle client not found
                }
            } catch (error) {
                console.error("Failed to fetch client data:", error);
            } finally {
                setLoading(false); // End loading
            }
        }

        fetchData(); // Call the function to fetch data when the component mounts
    }, [name]); // Dependency array includes 'name' to re-run if name changes

    // If data is still loading, show a loading message
    if (loading) {
        return <p>Učitavamo podatke</p>;
    }

    // If no client data is found, show an error message
    if (!clientData) {
        return <p>Klijent nije pronađen</p>;
    }

    // Function to handle password input change
    const handlePasswordChange = (e) => {
        setPassword(e.target.value);
    };

    // Function to handle password submission
    const handlePasswordSubmit = () => {
        if (password === clientData.password || password === clientData.gostiPassword) {
            setIsAuthorized(true); // Set isAuthorized to true to show loader and message
            setTimeout(() => {
                // After 3 seconds, redirect the user
                if (password === clientData.password) {
                    window.location.href = clientData.url; // Redirect to client's main URL
                } else if (password === clientData.gostiPassword) {
                    window.location.href = clientData.gostiUrl; // Redirect to guest URL
                }
            }, 3000); // Wait 3 seconds before redirecting
        } else {
            alert("Neispravan password, molimo vas pokušajte ponovo!"); // Show alert message
            setPassword(""); // Clear password input field
            setIsAuthorized(false); // Reset authorization state
        }
    };

    // If user is authorized or redirect is set to true, show the thank you message and logo before redirect
    if (isAuthorized) {
        return (
            <div className="h-[90vh] w-[90vw] mx-auto mb-9 flex flex-col items-center justify-center">
                <h3 className="text-3xl mt-4 text-center text-white">Hvala što ste odabrali</h3>
                <img src={Logo} alt="logo" className="mx-auto" />
                <img src={Loading} alt="logo" className="mx-auto h-20"/>
                <p className="text-xl mt-4 text-center text-white">Preusmjeravamo vas na fotografije</p>
            </div>
        );
    }

    return (
        <div>
            <MenuComponent showAll={false} />
            <div className="h-[90vh] w-[90vw] mx-auto mb-9">
                <div className="password-check text-white text-center">
                    {clientData.image && (
                        <img src={clientData.image} alt="logo" className="mx-auto mb-4 lg:w-1/2"/>
                    )}
                    <h3 className="text-3xl mb-4">{clientData.title}</h3>
                    {clientData.redirect !== "true" && (
                        <>
                            <h2>Da biste vidjeli fotografije, molimo vas unesite lozinku</h2>
                            <input 
                                type="password" 
                                value={password} 
                                onChange={handlePasswordChange} 
                                placeholder="Vaš password"
                                className="p-2 m-2 rounded-md text-black text-xl"
                            />
                            <button 
                                onClick={handlePasswordSubmit} 
                                className="p-2 m-2 bg-blue-500 text-white rounded-md"
                            >
                                Potvrdi
                            </button>
                        </>
                    )}
                </div>
                <InstagramFollow/>
            </div>
            <Footer />
        </div>
    );
}

export default ClientPage;
