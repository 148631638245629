import App from "../App"
import HomeComponents from "../components/HomeComponents"
import ClientPage from "../pages/ClientPage"
import PackagePage from "../pages/PackagePage"


const routes = [
    {
        path: "/",
        element: <App />,
        children: [
            {
                path: "/",
                element: <HomeComponents />,
            },
            {
                path: "/ponuda/:id",
                element: <PackagePage/>,
            },
            {
                path: "/client/:name",
                element: <ClientPage/>,
            }
            
        ],
       
        
    }
  
]

export default routes